<template>
  <b-card
    class="top-level"
  >
    <b-form>
      <b-row align-v="end">

        <b-col
          class="px-2"
          cols="12"
          md="8"
        >
          <span
            class="text-nowrap flex-parent my-0"
          >
            <h2 v-if="order.identification">{{ order.identification }}.&nbsp;</h2>
            <b-avatar
              :src="gravatar_url"
              class="mx-1"
            />
            <h4 class="flex-child long-and-truncated">{{ order.client.full_name ? order.client.full_name : 'Novo Pedido' }}</h4>
          </span>
        </b-col>
        <b-col
          cols="12"
          md="4"
          align="right"
          class="py-1"
        >
          <b-row
            align-v="end"
          >
            <b-col
              cols="12"
              md="6"
              class="mb-1 mb-md-0"
            >
              <b-button
                class="bg-white"
                variant="outline-success"
                :disabled="state === 'loading'"
                @click="saveOrder(false)"
              >
                {{ order.id ? 'Gravar e continuar editando' : 'Adicionar pedido e continuar editando' }}
              </b-button>

            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-button
                variant="success"
                :disabled="state === 'loading'"
                @click="saveOrder(true)"
              >
                {{ order.id ? 'Gravar' : 'Adicionar pedido' }}
              </b-button>

            </b-col>
          </b-row>

        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card-actions
            title="Dados do pedido"
            action-collapse
          >
            <b-row>

              <b-col
                class="px-2"
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Identificação"
                  label-for="identification"
                >
                  <b-form-input
                    id="identification"
                    v-model="order.identification"
                    :state="orderValidation.identification.state"
                    :disabled="state === 'loading' || !orderEditable"
                  />
                  <b-form-invalid-feedback>
                    {{ orderValidation.identification.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                class="px-2"
                cols="12"
                md="4"
              >
                <b-form-group
                  label="status"
                  label-for="status"
                >
                  <v-select
                    id="status"
                    v-model="order.status"
                    label="text"
                    :options="statusOptions"
                    :reduce="c => c.value"
                    :clearable="false"
                    :selectable="(option) => option.value !== 'DISABLED'"
                    :disabled="state === 'loading'"
                  >
                    <template #no-options="{}">
                      A lista de opções está vazia
                    </template>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ orderValidation.status.feedback }}
                  </b-form-invalid-feedback>
                  <span
                    v-if="!orderEditable"
                    class="text-helper"
                  >
                    Os pedidos só podem ser editados se estiverem com status não pago.
                  </span>
                </b-form-group>
              </b-col>
              <b-col
                class="px-2"
                cols="12"
                md="4"
              >
                <b-form-group
                  label="order date"
                  label-for="order_date"
                >
                  <b-form-datepicker
                    id="order_date"
                    v-model="order.order_date"
                    locale="pt-BR"
                    :state="orderValidation.order_date.state"
                    :disabled="state === 'loading' || !orderEditable"
                    class="mb-2"
                    placeholder="Data"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short' }"
                  />
                  <b-form-invalid-feedback>
                    {{ orderValidation.order_date.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                class="px-2"
                cols="12"
                md="4"
              >
                <b-form-group
                  label="NFe"
                  label-for="nfe_number"
                >
                  <b-form-input
                    id="nfe_number"
                    v-model="order.nfe_number"
                    :state="orderValidation.nfe_number.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ orderValidation.nfe_number.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                class="px-2"
                cols="12"
                md="4"
              >
                <b-form-group
                  v-if="!order.shippingchoice"
                  label="frete"
                  label-for="delivery"
                >
                  <b-form-input
                    id="delivery"
                    v-model="order.delivery"
                    :state="orderValidation.delivery.state"
                    :disabled="state === 'loading' || !orderEditable"
                  />
                  <b-form-invalid-feedback>
                    {{ orderValidation.delivery.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  v-else
                  label="frete"
                  label-for="delivery"
                >
                  <b-form-input
                    id="delivery"
                    v-model="order.shippingchoice.price"
                    :state="orderValidation.delivery.state"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                class="px-2"
                cols="12"
                md="4"
              >
                <b-form-group
                  v-if="!order.shippingchoice"
                  label="Serviço de frete"
                  label-for="frete_provider"
                >
                  <v-select
                    id="frete_provider"
                    v-model="order.frete_provider"
                    label="text"
                    :options="deliveryOptions"
                    :reduce="c => c.value"
                    :clearable="false"
                    :disabled="state === 'loading' || !orderEditable"
                  >
                    <template #no-options="{}">
                      A lista de opções está vazia
                    </template>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ orderValidation.frete_provider.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  v-else
                  label="Serviço de frete"
                  label-for="frete_provider"
                >
                  <v-select
                    id="frete_provider"
                    :value="order.shippingchoice.shipping_option_company_name + ' - ' + order.shippingchoice.shipping_option_name"
                    label="text"
                    :clearable="false"
                    disabled
                  >
                    <template #no-options="{}">
                      A lista de opções está vazia
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col
                class="px-2"
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Número pedido de frete"
                  label-for="frete_tracking_number"
                >
                  <b-form-input
                    id="frete_tracking_number"
                    v-model="order.frete_tracking_number"
                    :state="orderValidation.frete_tracking_number.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ orderValidation.frete_tracking_number.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card-actions
            title="Cliente e Endereço"
            action-collapse
          >
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group
                  label="Pesquisar"
                  label-for="address_search"
                >
                  <b-form-input
                    id="address_search"
                    v-model="address_search"
                    :disabled="state === 'loading' || !orderEditable"
                    @change="(value)=>searchAddresses()"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-for="address in addresses"
                :key="address.id"
                lg="3"
                sm="6"
                cols="12"
              >
                <b-card
                  bg-variant="dark"
                  text-variant="white"
                  @click="chooseAddress(address.id)"
                >
                  <h5 class="card-title m-0 text-white">
                    {{ address.full_name }}
                  </h5>
                  <p class="card-text m-0">
                    {{ address.address }}, {{ address.number }}
                  </p>
                  <p class="card-text m-0">
                    {{ address.cep }}, {{ address.state }}, {{ address.city }}
                  </p>
                </b-card>
              </b-col>
            </b-row>
            <b-row>

              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Nome Completo"
                  label-for="full_name"
                >
                  <b-form-input
                    id="full_name"
                    v-model="order.client.full_name"
                    :state="orderValidation.client.full_name.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ orderValidation.client.full_name.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="CPF / CNPJ"
                  label-for="cpf_cnpj"
                >
                  <b-form-input
                    id="cpf_cnpj"
                    v-model="order.client.cpf_cnpj"
                    v-mask="CPF_CNPJ_mask"
                    :state="orderValidation.client.cpf_cnpj.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ orderValidation.client.cpf_cnpj.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="CEP"
                  label-for="cep"
                >
                  <b-form-input
                    id="cep"
                    v-model="order.client.cep"
                    v-mask="'#####-###'"
                    :state="orderValidation.client.cep.state"
                    :disabled="state === 'loading'"
                    @change="getCEP"
                  />
                  <b-form-invalid-feedback>
                    {{ orderValidation.client.cep.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Endereço"
                  label-for="address"
                >
                  <b-form-input
                    id="address"
                    v-model="order.client.address"
                    :state="orderValidation.client.address.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ orderValidation.client.address.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Número"
                  label-for="number"
                >
                  <b-form-input
                    id="number"
                    ref="number"
                    v-model="order.client.number"
                    :state="orderValidation.client.number.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ orderValidation.client.number.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Complemento"
                  label-for="complement"
                >
                  <b-form-input
                    id="complement"
                    v-model="order.client.complement"
                    :state="orderValidation.client.complement.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ orderValidation.client.complement.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Bairro"
                  label-for="neighbourhood"
                >
                  <b-form-input
                    id="neighbourhood"
                    v-model="order.client.neighbourhood"
                    :state="orderValidation.client.neighbourhood.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ orderValidation.client.neighbourhood.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="9"
              >
                <b-form-group
                  label="Cidade"
                  label-for="city"
                >
                  <b-form-input
                    id="city"
                    v-model="order.client.city"
                    :state="orderValidation.client.city.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ orderValidation.client.city.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="UF"
                  label-for="state"
                >
                  <b-form-input
                    id="state"
                    v-model="order.client.state"
                    :state="orderValidation.client.state.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ orderValidation.client.state.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Telefone"
                  label-for="phone"
                >
                  <b-form-input
                    id="phone"
                    v-model="order.client.phone"
                    :state="orderValidation.client.phone.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ orderValidation.client.phone.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="E-mail"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="order.client.email"
                    type="email"
                    :state="orderValidation.client.email.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ orderValidation.client.email.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
              >
                <b-form-group
                  label="Informações adicionais"
                  label-for="additional_info"
                >
                  <b-form-input
                    id="additional_info"
                    v-model="order.client.additional_info"
                    :state="orderValidation.client.additional_info.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ orderValidation.client.additional_info.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card-actions
            title="Produtos"
            action-collapse
          >
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group
                  label="Adicionar produto"
                  label-for="add_product"
                >
                  <v-select
                    id="add_product"
                    v-model="newProduct"
                    label="name"
                    :options="$store.state.products.productsList"
                    :filterable="false"
                    :disabled="state === 'loading' || !orderEditable"
                    @input="addProduct"
                    @search="onSearch"
                  >
                    <template #no-options="{}">
                      A lista de opções está vazia
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-for="choice in order.product_choices"
                :key="choice.product"
                cols="12"
              >
                <b-card
                  no-body
                  class="pt-1 px-1"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <span
                        class="text-nowrap flex-parent my-1"
                      >
                        <b-avatar
                          :src="choice.thumbnail_url"
                          class="mr-1"
                        />
                        <span class="flex-child long-and-truncated">{{ choice.name }}</span>
                      </span>

                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-form-group
                        label="Anotação"
                        :label-for="`additional_info_${choice.product}`"
                      >
                        <b-form-input
                          :id="`additional_info_${choice.product}`"
                          v-model="choice.additional_info"
                          :disabled="state === 'loading'"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-form-group
                        label="Quantidade"
                        :label-for="`quantity_${choice.product}`"
                      >
                        <b-form-input
                          :id="`quantity_${choice.product}`"
                          v-model="choice.quantity"
                          :disabled="state === 'loading' || !orderEditable"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-form-group
                        label="Preço"
                        :label-for="`value_${choice.product}`"
                      >
                        <b-form-input
                          :id="`value_${choice.product}`"
                          v-model="choice.price"
                          :disabled="state === 'loading' || !orderEditable"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-form-group class="mt-2">
                        <b-button
                          variant="danger"
                          :disabled="state === 'loading' || !orderEditable"
                          @click="removeProduct(choice.product)"
                        ><feather-icon icon="Trash2Icon" /></b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card-actions
            title="Descontos"
            action-collapse
          >
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group
                  label="Adicionar desconto"
                  label-for="add_discount"
                >
                  <v-select
                    id="add_discount"
                    v-model="newDiscount"
                    label="code"
                    :options="$store.state.discounts.discountsList"
                    :filterable="false"
                    :disabled="state === 'loading' || !orderEditable"
                    @input="addDiscount"
                    @search="onSearchDiscount"
                  >
                    <template #no-options="{}">
                      A lista de opções está vazia
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-for="discount in order.discounts"
                :key="discount.id"
                cols="12"
              >
                <b-card
                  no-body
                  class="px-1 mb-0"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <span
                        class="text-nowrap flex-parent my-1"
                      >
                        <span class="flex-child long-and-truncated">{{ discount.code }}</span>
                      </span>

                    </b-col><b-col
                      cols="12"
                      md="4"
                    >
                      <span
                        class="text-nowrap flex-parent my-1"
                      >
                        <span class="flex-child long-and-truncated">
                          {{ `${discount.value[0].discount} ${discount.value[0].unit}` }}
                        </span>
                      </span>

                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group class="mt-2">
                        <b-button
                          variant="danger"
                          :disabled="state === 'loading' || !orderEditable"
                          @click="removeDiscount(discount.id)"
                        ><feather-icon icon="Trash2Icon" /></b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>

      </b-row>
      <b-row>
        <b-col
          cols="12"
          align="right"
          class="mb-1"
        >
          <b-row
            align-v="end"
            align="right"
          >
            <b-col
              cols="12"
              md="9"
              class="mb-1 mb-md-0"
            >
              <b-button
                class="bg-white"
                variant="outline-success"
                :disabled="state === 'loading'"
                @click="saveOrder(false)"
              >
                {{ order.id ? 'Gravar e continuar editando' : 'Adicionar pedido e continuar editando' }}
              </b-button>

            </b-col>

            <b-col
              cols="12"
              md="3"
            >
              <b-button
                variant="success"
                :disabled="state === 'loading'"
                @click="saveOrder(true)"
              >
                {{ order.id ? 'Gravar' : 'Adicionar pedido' }}
              </b-button>

            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, VBPopover, BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BForm,
  BFormCheckbox, BMedia, BFormInvalidFeedback, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import md5 from 'md5'
import axios from 'axios'

export default {
  components: {
    vSelect,
    BFormCheckbox,
    BForm,
    BButton,
    BCardActions,
    BCard,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BMedia,
    BFormInvalidFeedback,
    BFormDatepicker,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    orderId: {
      type: String,
      required: false,
      default: '-1',
    },
  },
  data() {
    return {
      paidStatuses: ['RESERVED', 'PAID', 'SENT', 'DELIVERED'],
      addresses: [],
      address_search: '',
      newProduct: null,
      newDiscount: null,
      allSelected: false,
      selected: [],
      searchFilter: null,
      state: 'ready',
      dir: false,
      validation: false,
      statusOptions: [
        {
          text: 'Pedido realizado',
          value: 'CREATED',
        },
        {
          text: 'Reservado, esperando compensação',
          value: 'RESERVED',
        },
        {
          text: 'Pago',
          value: 'PAID',
        },
        {
          text: 'Enviado',
          value: 'SENT',
        },
        {
          text: 'Entregue',
          value: 'DELIVERED',
        },
        {
          text: 'Estornado',
          value: 'REFUNDED',
        },
        {
          text: 'Cancelado',
          value: 'CANCELED',
        },
      ],
      deliveryOptions: [
        {
          text: 'Jadlog',
          value: 'jadlog',
        },
        {
          text: 'Correios/ME',
          value: 'correios_me',
        },
        {
          text: 'Azul/ME',
          value: 'azul_me',
        },
        {
          text: 'Latam Cargo/ME',
          value: 'latam_cargo_me',
        },
        {
          text: 'Mercado Livre',
          value: 'mercado_livre',
        },
        {
          text: 'Retirada',
          value: 'retirada',
        },
      ],
      order: {
        status: 'CREATED',
        order_date: null,
        delivery: 0,
        identification: null,
        frete_provider: 'jadlog',
        frete_tracking_number: null,
        nfe_number: null,
        product_choices: [],
        discounts: [],
        client: {
          full_name: '',
          cpf_cnpj: '',
          cep: '',
          address: '',
          number: '',
          complement: '',
          neighbourhood: '',
          city: '',
          state: '',
          phone: '',
          email: '',
          additional_info: '',
        },
      },
      validationBase: {
        status: {
          state: null,
          feedback: '',
        },
        order_date: {
          state: null,
          feedback: '',
        },
        delivery: {
          state: null,
          feedback: '',
        },
        identification: {
          state: null,
          feedback: '',
        },
        frete_provider: {
          state: null,
          feedback: '',
        },
        frete_tracking_number: {
          state: null,
          feedback: '',
        },
        nfe_number: {
          state: null,
          feedback: '',
        },
        product_choices: {
          state: null,
          feedback: '',
        },
        client: {
          full_name: {
            state: null,
            feedback: '',
          },
          cpf_cnpj: {
            state: null,
            feedback: '',
          },
          cep: {
            state: null,
            feedback: '',
          },
          address: {
            state: null,
            feedback: '',
          },
          number: {
            state: null,
            feedback: '',
          },
          complement: {
            state: null,
            feedback: '',
          },
          neighbourhood: {
            state: null,
            feedback: '',
          },
          city: {
            state: null,
            feedback: '',
          },
          state: {
            state: null,
            feedback: '',
          },
          phone: {
            state: null,
            feedback: '',
          },
          email: {
            state: null,
            feedback: '',
          },
          additional_info: {
            state: null,
            feedback: '',
          },
        },
      },
      orderValidation: {
        status: {
          state: null,
          feedback: '',
        },
        order_date: {
          state: null,
          feedback: '',
        },
        delivery: {
          state: null,
          feedback: '',
        },
        identification: {
          state: null,
          feedback: '',
        },
        frete_provider: {
          state: null,
          feedback: '',
        },
        frete_tracking_number: {
          state: null,
          feedback: '',
        },
        nfe_number: {
          state: null,
          feedback: '',
        },
        product_choices: {
          state: null,
          feedback: '',
        },
        client: {
          full_name: {
            state: null,
            feedback: '',
          },
          cpf_cnpj: {
            state: null,
            feedback: '',
          },
          cep: {
            state: null,
            feedback: '',
          },
          address: {
            state: null,
            feedback: '',
          },
          number: {
            state: null,
            feedback: '',
          },
          complement: {
            state: null,
            feedback: '',
          },
          neighbourhood: {
            state: null,
            feedback: '',
          },
          city: {
            state: null,
            feedback: '',
          },
          state: {
            state: null,
            feedback: '',
          },
          phone: {
            state: null,
            feedback: '',
          },
          email: {
            state: null,
            feedback: '',
          },
          additional_info: {
            state: null,
            feedback: '',
          },
        },
      },
    }
  },
  computed: {
    gravatar_url() {
      return this.get_gravatar_image_url(this.order.client.email)
    },
    orderEditable() {
      return !this.paidStatuses.includes(this.order.status)
    },
  },
  watch: {
    searchFilter(val) {
      this.pesquisa()
    },
  },
  created() {
    if (this.orderId !== '-1') {
      this.state = 'loading'
      this.$store.dispatch('orders/getOrderById', this.orderId).then(() => {
        this.order = this.$store.state.orders.orderData
      }).finally(() => {
        this.state = 'ready'
      })
    }
  },
  methods: {
    CPF_CNPJ_mask(value) {
      const numbers = value.replace(/[^0-9]/g, '')
      if (numbers.length <= 11) {
        return [...numbers.substring(0, 3), '.', ...numbers.substring(3, 6), '.', ...numbers.substring(6, 9), '-', ...numbers.substring(9, 11)]
      }
      return [...numbers.substring(0, 2), '.', ...numbers.substring(2, 5), '.', ...numbers.substring(5, 8), '/', ...numbers.substring(8, 12), '-', ...numbers.substring(12, 14)]
    },
    get_gravatar_image_url(email, size, default_image, allowed_rating, force_default) {
      email = typeof email !== 'undefined' ? email : 'john.doe@example.com'
      size = (size >= 1 && size <= 2048) ? size : 100
      default_image = typeof default_image !== 'undefined' ? default_image : 'mp'
      allowed_rating = typeof allowed_rating !== 'undefined' ? allowed_rating : 'g'
      force_default = force_default === true ? 'y' : 'n'

      return (`https://secure.gravatar.com/avatar/${md5(email.toLowerCase().trim())}?size=${size}&default=${encodeURIComponent(default_image)}&rating=${allowed_rating}${force_default === 'y' ? `&forcedefault=${force_default}` : ''}`)
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.$store.dispatch('products/getProducts', {
          search,
          page: 1,
        }).then(() => {
          loading(false)
        })
          .catch(() => {
            loading(false)
          })
      }
    },
    onSearchDiscount(search, loading) {
      loading(true)
      this.$store.dispatch('discounts/getDiscounts', {
        search,
        page: 1,
      }).then(() => {
        loading(false)
      })
        .catch(() => {
          loading(false)
        })
    },
    addProduct() {
      const index = this.order.product_choices.map(o => o.product).indexOf(this.newProduct.id)
      if (index !== -1) {
        this.order.product_choices.splice(index, 1)
      }
      this.newProduct.product = this.newProduct.id
      this.newProduct.price = this.newProduct.sale ? this.newProduct.discounted_price : this.newProduct.price
      this.newProduct.quantity = 1
      this.order.product_choices.push({ ...this.newProduct })
      this.order.product_choices = this.order.product_choices.sort((a, b) => a.name > b.name)
      // this.students = this.students.filter((item, i) => this.students.indexOf(item) === i).filter(item => item !== this.newStudent.id)
      // this.students.push(this.newStudent.id)
      this.newProduct = null
    },
    removeProduct(id) {
      const index = this.order.product_choices.map(o => o.product).indexOf(id)
      if (index !== -1) {
        this.order.product_choices.splice(index, 1)
      }
    },
    addDiscount() {
      const index = this.order.discounts.map(o => o.id).indexOf(this.newDiscount.id)
      if (index !== -1) {
        this.order.discounts.splice(index, 1)
      }
      this.order.discounts.push({ ...this.newDiscount })
      this.newDiscount = null
    },
    removeDiscount(id) {
      const index = this.order.discounts.map(o => o.id).indexOf(id)
      if (index !== -1) {
        this.order.discounts.splice(index, 1)
      }
    },
    saveOrder(goToOrdersList) {
      this.$store.dispatch('orders/createOrUpdateOrder', this.order).then(() => {
        if (goToOrdersList) {
          this.$router.push('/orders')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Pedido',
              icon: 'SuccessIcon',
              variant: 'success',
              text: 'Pedido gravado com sucesso!',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Pedido',
              icon: 'SuccessIcon',
              variant: 'success',
              text: 'Pedido gravado com sucesso!',
            },
          })
        }
      }).catch(error => {
        this.orderValidation = JSON.parse(JSON.stringify(this.validationBase))
        /* eslint-disable */
          for (const [k, v] of Object.entries(error.response.data)) {
            if (Array.isArray(v)) {
              this.orderValidation[k] = {
                state: false,
                feedback: v[0],
              }
            } else {
              for (const [k2, v2] of Object.entries(v)) {
                this.orderValidation[k][k2] = {
                  state: false,
                  feedback: v2[0],
                }
              }
            }
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Pedido',
              icon: 'DangerIcon',
              variant: 'danger',
              text: 'Não conseguimos gravar este pedido. Por favor verificar.',
            },
          })
      })
    },
    getCEP(cep) {
      this.state = 'loading'
      axios.get(
        `https://viacep.com.br/ws/${cep}/json/ `,
        { crossdomain: true }
      ).then((response) => {
        if (!response.data.erro) {
          this.order.client.address = response.data.logradouro
          this.order.client.neighbourhood = response.data.bairro
          this.order.client.city = response.data.localidade
          this.order.client.state = response.data.uf
          this.order.client.complement = response.data.complemento
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'CEP não encontrado',
              icon: 'DangerIcon',
              variant: 'warning',
            },
          })
        }
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'CEP não encontrado',
            icon: 'DangerIcon',
            variant: 'warning',
          },
        })
      }).finally(() => {
        this.state = 'ready'
        this.$refs.number.$el.disabled = false
        this.$refs.number.$el.focus()
      })
    },
    searchAddresses() {
      this.state = 'loading'
      this.$store.dispatch('users/getAddresses', this.address_search).then(
        result => {
          this.addresses = result.data.results
        }
      ).finally(() => {
        this.state = 'ready'
      })
    },
    chooseAddress(addressId) {
      const address = this.addresses.find(elt => elt.id === addressId)
      if(!address){
        return
      }
      this.order.client.address = address.address
      this.order.client.neighbourhood = address.neighbourhood
      this.order.client.city = address.city
      this.order.client.state = address.state
      this.order.client.cep = address.cep
      this.order.client.full_name = address.full_name
      this.order.client.complement = address.complement
      this.order.client.additional_info = address.additional_info
      this.order.client.number = address.number
      this.order.client.email = address.email
      this.order.client.phone = address.phone
      this.order.client.cpf_cnpj = address.cpf_cnpj
      this.addresses = []
      this.address_search = ''
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';

.flex-parent {
  display: flex;
  align-items: center;
  padding: 10px;
}

.long-and-truncated {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-helper {
  font-size: 0.8rem;
  color: black;
  font-weight: 700;
}
</style>
